export const CloseIcon = ({
  className = 'h-5 w-5',
  stroke = '#757575',
  fill = '#F0F0F0',
}) => {
  return (
    // <svg
    //   viewBox="0 0 20 20"
    //   fill="none"
    //   xmlns="http://www.w3.org/2000/svg"
    //   className={className}
    // >
    //   <path
    //     d="M19 19L1 1M19 1L1 19"
    //     stroke="currentColor"
    //     strokeWidth="2"
    //     strokeLinecap="round"
    //   />
    // </svg>

    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      className={className}
    >
      <circle cx="12" cy="12" r="12" fill={fill} />
      <path
        d="M16 8L8 16"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8 8L16 16"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
